import { CARD_DESIGNS, CARD_LEVELS } from 'qonto/constants/cards';

interface GetColorProps {
  cardDesign: string;
  cardLevel: string;
  t: (key: string) => string;
}

export function getColor({ cardDesign, cardLevel, t }: GetColorProps): string | null {
  if (CARD_LEVELS.PLUS === cardLevel) {
    switch (cardDesign) {
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_LILAC_2023:
        return t('cards.colors.plus.light-purple');
      case CARD_DESIGNS.PLUS_RECYCLED_PLASTIC_BLACK_2023:
        return t('cards.colors.plus.black');
      default:
        return t('cards.colors.plus.silver');
    }
  }

  if (CARD_LEVELS.METAL === cardLevel) {
    switch (cardDesign) {
      case CARD_DESIGNS.METAL_MINERAL_GRAY_2024:
        return t('cards.colors.metal.mineral-gray');
      case CARD_DESIGNS.METAL_SAND_GOLD_2024:
        return t('cards.colors.metal.sand-gold');
      case CARD_DESIGNS.METAL_GRAPHITE_BLACK_2019:
        return t('cards.colors.metal.graphite-black');
      default:
        return null;
    }
  }

  return null;
}
