// @ts-nocheck
import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class ZendeskLocalizationService extends Service {
  @service organizationManager;
  @service localeManager;
  @service sessionManager;

  ZENDESK_DEFAULTS = {
    UNAUTH_FALLBACK_COUNTRY: 'fr',
    UNAUTH_FALLBACK_LOCALE: 'en',
    AUTH_FALLBACK_LOCALE: 'en',
  };

  COUNTRY_TO_HELP_CENTER_MAP = {
    fr: 'fr',
    de: 'de',
    it: 'it',
    es: 'es',
    // Fallback for FAQ articles that are not localized
    pt: 'fr',
    at: 'fr',
    be: 'fr',
    nl: 'fr',
  };

  ZENDESK_FAQ_IDS = {
    4359659: { fr: 23947722927249, de: 23949255133457, it: 23949973088145, es: 23951013010065 },
    4560206: { fr: 23947679356817, de: 23949283320977, it: 23949957454353, es: 23950983707409 },
    5045700: { fr: 23947591709457, de: 23949199514385, it: 23949947229841, es: 23950919490833 },
    4704448: { fr: 23947663385361, de: 23949268610193, it: 23949944087185, es: 23951002573457 },
    4359548: { fr: 23947692631825, de: 23949284744593, it: 23949997871761, es: 23951029272081 },
    4819435: { fr: 23947681308177, de: 23949232332305, it: 23949971182097, es: 23950968161809 },
    4359572: { fr: 23947717105169, de: 23949238255761, it: 23949989824529, es: 23951024072081 },
    6952381: { fr: 23947627043729, de: 23949233278993, it: 23949962713489, es: 23950925341201 },
    4674610: { fr: 23947682067985, de: 23949222979985, it: 23949957227025, es: 23950992516113 },
    7206518: { fr: 23947595341841, de: 23949213139729, it: 23949895170065, es: 23950938696849 },
    7206496: { fr: 23947604815633, de: 23949227392785, it: 23949949195409, es: 23950932465169 },
    4359614: { fr: 23947715457297, de: 23949270560273, it: 23949973995921, es: 23951001405457 },
    9015327: { fr: undefined, de: undefined, it: undefined, es: undefined },
    6784892: { fr: 23947581127441, de: 23949227806993, it: 23949946207505, es: 23950868319889 },
    6784897: { fr: 23947605128849, de: 23949197790609, it: 23949934952977, es: 23950953154065 },
    8981507: { fr: 23947598547985, de: undefined, it: 23949931720081, es: 23950882390417 },
    8271688: { fr: 24475495156881, de: 23949210437009, it: 23949918640529, es: 23950919826961 },
    6456683: { fr: undefined, de: 23949214964497, it: undefined, es: undefined },
    6456891: { fr: undefined, de: undefined, it: undefined, es: undefined },
    9105540: { fr: 24835439794833, de: 24835877608337, it: 24835842852625, es: 24835882281873 },
    4359525: { fr: 23947696072721, de: 23949292113937, it: 23949991315473, es: 23951040015633 },
    4359570: { fr: 23947694535569, de: 23949259949073, it: 23949960610961, es: 23951030848657 },
    4359530: { fr: 23947688152977, de: 23949272305425, it: 23949961087889, es: 23951031503121 },
    5856637: { fr: 23947665588881, de: 23949267077265, it: 23949941350289, es: 23950988068625 },
    4470305: { fr: 23947683075857, de: 23949242110865, it: 23949969471505, es: 23951003546257 },
    6788202: { fr: 23947630401681, de: 23949214351889, it: 23949950234385, es: 23950909742097 },
    7958842: { fr: 23947623005201, de: 23949200354961, it: 23949933565457, es: 23950928816529 },
    6211274: { fr: 23947608620305, de: 23949195799569, it: 23949907221137, es: 23950898831121 },
    7958908: { fr: 23947603083537, de: 23949216285585, it: 23949894422417, es: 23950930431633 },
    4359526: { fr: 23947726568977, de: 23949286745745, it: 23949975789329, es: 23951008451985 },
    7936264: { fr: 23947584856209, de: 23949196873233, it: 23949948751761, es: 23950901011089 },
    6768689: { fr: 23947604237713, de: 23949201770897, it: 23949908281489, es: 23950938065681 },
    9145313: { fr: 24850425156625, de: 25281643214737, it: 25276765652113, es: 25193162878609 },
    9145314: { fr: undefined, de: 24850649796497, it: undefined, es: undefined },
    8575782: { fr: 23947579160977, de: 23949208800145, it: 23949931652497, es: 23950882263825 },
    7896786: { fr: 23947603718801, de: 23949201499409, it: 23949908091921, es: 23950923349009 },
    5898299: { fr: 23947674128913, de: 23949221022097, it: 23949941256593, es: 23950947692689 },
    4359529: { fr: 23947740275601, de: 23949292824977, it: 23949976546833, es: 23951032940945 },
    4359620: { fr: 23947715184273, de: 23949258372113, it: 23950013576081, es: 23951005749393 },
    8002095: { fr: 23947593685009, de: 23949211817873, it: 23949948387857, es: 23950922077457 },
    4359569: { fr: 23947701967505, de: 23949275420945, it: 23949999378705, es: 23951007519249 },
    5165756: { fr: 23947668361361, de: 23949231950481, it: 23949953933969, es: 23950995738641 },
    5319222: { fr: 23947646001425, de: 23949231761937, it: 23949967365137, es: 23950949938705 },
    5258786: { fr: 23947651978897, de: 23949237547409, it: 23949942577425, es: 23950995396881 },
    4359555: { fr: 23947711177489, de: 23949276303505, it: 23949990938513, es: 23951017243409 },
    5842209: { fr: 23947644174993, de: 23949206250641, it: 23949966202129, es: 23950947963793 },
    4359541: { fr: 23947711844753, de: 23949246697873, it: 23950004508305, es: 23951055377041 },
    4359546: { fr: 23947689026321, de: 23949292338833, it: 23949961580945, es: 23951040439825 },
    4359537: { fr: 23947739917969, de: 23949246923409, it: 23949991779729, es: 23951040733073 },
    6884975: { fr: 23947612047633, de: 23949228051089, it: 23949935555089, es: 23950925626513 },
    4359566: { fr: 23947725795217, de: 23949245587217, it: 23950015256593, es: 23951024499217 },
    4359565: { fr: 23947694827537, de: 23949291324945, it: 23949960847377, es: 23951038949649 },
    4359550: { fr: 23947727003793, de: 23949260992145, it: 23949961425937, es: 23951017640209 },
    4359551: { fr: 23947703730961, de: 23949292168465, it: 23949961394065, es: 23951025739665 },
    8484807: { fr: 23947607384337, de: 23949199696017, it: 23949902225425, es: 23950903380241 },
    7222028: { fr: 23947611003153, de: 23949227154577, it: 23949908465937, es: 23950914566161 },
    4359616: { fr: 23947685520145, de: 23949255751185, it: 23949988349969, es: 23951013976209 },
    4359650: { fr: 23947714708881, de: 23949284336273, it: 23949987615377, es: 23951000439441 },
    5574856: { fr: undefined, de: undefined, it: undefined, es: undefined },
    5259491: { fr: 23947667710353, de: undefined, it: undefined, es: undefined },
    7266764: { fr: 23947595117073, de: undefined, it: undefined, es: undefined },
    8890964: { fr: 23947598889617, de: undefined, it: undefined, es: undefined },
    4359532: { fr: 23947728089617, de: undefined, it: undefined, es: undefined },
    7003907: { fr: 23947579782545, de: undefined, it: undefined, es: undefined },
    5231586: { fr: 23947646383505, de: 23949178941329, it: undefined, es: 23950990391953 },
    4359524: { fr: 23947712694033, de: 23949246749201, it: undefined, es: undefined },
    6533909: { fr: undefined, de: 23949229027857, it: 23949950685329, es: 23950935066001 },
    8218030: { fr: undefined, de: undefined, it: 23949893469329, es: undefined },
    5587992: { fr: undefined, de: undefined, it: undefined, es: undefined },
    4359535: { fr: 23947739978769, de: 23949286422801, it: 23950016673041, es: 23951016601745 },
    8684792: { fr: undefined, de: undefined, it: 23949918203793, es: undefined },
    4674606: { fr: 23947670125329, de: 23949223040657, it: 23949944205201, es: 23950977291409 },
    9235605: { fr: 25012107576209, de: 25302455344273, it: 25276624926225, es: 25193273020177 },
    9357709: { fr: 25317181693201, de: 25895540400273, it: 25317351863569, es: 25317436448017 },
    9507034: { fr: 26099611324561, de: 26103452161553, it: 26100478455953, es: 26099958537105 },
    3715946: { fr: 26023366545553, de: 26023417282961, it: 26023450387473, es: 26023376990993 },
    4987654: { fr: 26668002250001, de: 26746974986257, it: 26718692752017, es: 26718468270481 },
    9500598: { fr: 26570070089745, de: 26057885522193, it: 26572788437137, es: 26624250395921 },
    9167390: { fr: 24774970693521, de: 25045863185041, it: 24320116097553, es: 24234145227025 },
    9167401: { fr: 24400330200081, de: 27078560818833, it: 24323019852561, es: 24234162723473 },
    9023812: { fr: undefined, de: undefined, it: undefined, es: 23950951245073 },
    'insurance-hub': { fr: 27761716992529, de: 27767518045585, it: undefined, es: undefined },
    'insurance-hub-stello': { fr: 27761786559377, de: undefined, it: undefined, es: undefined },
    'insurance-hub-signal': { fr: undefined, de: 27767584407185, it: undefined, es: undefined },
    'fr-einvoice': { fr: 27885538176913, de: undefined, it: undefined, es: undefined },
  };

  @tracked _organizationCountry =
    this.COUNTRY_TO_HELP_CENTER_MAP[
      this.organizationManager?.organization?.legalCountry?.toLowerCase()
    ] || this.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;

  get organizationCountry() {
    return this._organizationCountry.toLowerCase();
  }

  set organizationCountry(value) {
    if (value)
      this._organizationCountry =
        this.COUNTRY_TO_HELP_CENTER_MAP[value.toLowerCase()] ||
        this.ZENDESK_DEFAULTS.UNAUTH_FALLBACK_COUNTRY;
  }

  calcZendeskLocale(locale = this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE) {
    let orgCountry = this.organizationCountry;
    let userLocale = locale.toLowerCase();
    let zendeskLocale = userLocale;

    if (orgCountry !== userLocale) {
      zendeskLocale = this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE;
    }
    return zendeskLocale;
  }

  get userLocale() {
    return this.calcZendeskLocale(
      this.localeManager?.locale || this.ZENDESK_DEFAULTS.AUTH_FALLBACK_LOCALE
    );
  }

  get localization() {
    let orgCountry = this.organizationCountry;
    let userLocale = this.userLocale;

    return {
      orgCountry,
      userLocale,
    };
  }

  get composeHCZendeskUrl() {
    return `https://support-${this.organizationCountry}.qonto.com/hc/${this.userLocale}`;
  }

  get composeSSOZendeskUrl() {
    let ZENDESK_SSO_BASE_URL =
      'https://qonto9015.zendesk.com/auth/v2/login/continue_with_sso_tracking';

    let paramsByCountry = {
      fr: {
        authOrigin: '17320677699345,true,true',
        brandId: '17320677699345',
      },
      it: {
        authOrigin: '23132128531089,true,true',
        brandId: '23132128531089',
      },
      es: {
        authOrigin: '23132160601361,true,true',
        brandId: '23132160601361',
      },
      de: {
        authOrigin: '23132188747153,true,true',
        brandId: '23132188747153',
      },
    };

    let { authOrigin, brandId } = paramsByCountry[this.organizationCountry];

    let authOriginParam = `auth_origin=${authOrigin}`;
    let brandIdParam = `brand_id=${brandId}`;
    let localeParam = `locale=${this.userLocale}`;
    let returnToParam = `return_to=${this.composeHCZendeskUrl}`;
    let queryString = `${authOriginParam}&${brandIdParam}&${localeParam}&${returnToParam}`;

    return `${ZENDESK_SSO_BASE_URL}?${queryString}`;
  }

  enableFAQAuthenticationInTesting = false;

  get getLocalizedHelpCenter() {
    if (!this.isProduction && !this.enableFAQAuthenticationInTesting) {
      return this.composeHCZendeskUrl;
    } else if (this.sessionManager.isAuthenticated) {
      return this.composeSSOZendeskUrl;
    } else {
      return this.composeHCZendeskUrl;
    }
  }

  getLocalizedArticle(articleId) {
    let orgCountry = this.organizationCountry;

    if (this.ZENDESK_FAQ_IDS[articleId]?.[orgCountry]) {
      return `${this.getLocalizedHelpCenter}/articles/${this.ZENDESK_FAQ_IDS[articleId]?.[orgCountry]}`;
    } else {
      return this.getLocalizedHelpCenter;
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'zendesk-localization': ZendeskLocalizationService;
  }
}
