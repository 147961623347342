import Service, { service } from '@ember/service';
import { htmlSafe } from '@ember/template';

import { isTesting, macroCondition } from '@embroider/macros';
import dayjs from 'dayjs';
import { dropTask, timeout } from 'ember-concurrency';
import { reads } from 'macro-decorators';

import { KYCB_UPDATE_BANNER_STORAGE_KEY } from 'qonto/components/topbar/kyc-kyb-pending-update';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';
import getAnnouncement from 'qonto/utils/get-announcement';

const THROTTLE_ANNOUNCEMENT_S = macroCondition(isTesting()) ? 1 : 5 * 60 * 1000;

const ROUTES_WITH_NO_BANNERS = [
  /^cards\.activate$/,
  /^cards\.new\./,
  /^cards\.physical-or-virtual.?\w*$/,
  /^cards\.renew\.physical$/,
  /^cards\.settings\./,
  /^flows$/,
  /^settings\.financing\.request$/,
  /^flex-kyb/,
  /^upload-power-of-attorney/,
  /^kyc/,
  /^quotes\.new/,
];

const ROUTES_WITH_NO_BANNERS_MOBILE = [
  /^settings\.connect-hub\.applications\.hub-application\.setup$/,
];

const KYC_KYB_BANNER_ID = 'KYC-KYB-PENDING-UPDATE-BANNER';

export default class BannersService extends Service {
  /** @type {import('./banner-flash-messages').default} */
  @service bannerFlashMessages;
  @service intl;
  @service localeManager;
  @service organizationManager;
  @service abilities;
  @service router;
  @service cardsManager;
  @service sentry;
  @service zendeskLocalization;
  @service productDiscovery;
  @service periodicUpdate;
  @service store;
  @service topBanners;
  @service deviceManager;

  @reads('cardsManager.counters') cardCounters;

  async triggerBanners() {
    let { intl } = this;
    let { currentAccount, organization, membership } = this.organizationManager;

    // If the organization is under registration, we don't want to display any banners
    if (organization.underRegistration) {
      return;
    }

    let { currentRouteName } = this.router;

    let isBannerHiddenForRoute = ROUTES_WITH_NO_BANNERS.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    let isBannerHiddenForRouteOnMobile = ROUTES_WITH_NO_BANNERS_MOBILE.some(routeRegex =>
      routeRegex.test(currentRouteName)
    );

    if (isBannerHiddenForRoute || (isBannerHiddenForRouteOnMobile && this.deviceManager.isMobile)) {
      this.bannerFlashMessages.clearTopBannerMessages();
      return;
    }

    let didShowTopBanner = await this.topBanners.triggerBanners();
    if (didShowTopBanner) {
      return;
    }

    let messageOptions = {
      qonto_kyb_and_kyc_alerts_url: intl.t('topbar_msg.qonto_kyb_and_kyc_alerts_url', {
        organizationSlug: organization.slug,
        faqUrl: this.zendeskLocalization.getLocalizedArticle(4359529),
      }),
      qonto_kyb_and_kyc_alerts_url_text: intl.t('topbar_msg.qonto_kyb_and_kyc_alerts_url_text'),
      htmlSafe: true,
    };

    let isKybPending = organization.kybPending;
    let isKybRefused = organization.kybRefused;

    let isKycPending = membership.kycPending;
    let isKycRefused = membership.kycRefused;
    let isKycSubmitted = membership.kycSubmitted;
    let isKycRequired = membership.kycRequired;
    let isKycWaitingDocument = false;

    if (isKycRefused) {
      // membership.kycStatus is received as `refused` when `waiting_document` thus we leverage a new endpoint that returns the correct kyc for these cases.
      let kyc = await this.store.queryRecord('identitiesKyc', {
        identityId: membership.identityId,
      });

      isKycWaitingDocument = kyc.kycWaitingDocument;
    }

    let { isProductDiscoverySystemFeatureEnabled } = this.productDiscovery;
    if (isProductDiscoverySystemFeatureEnabled) {
      let updateProcess = await this.periodicUpdate.fetchUpdateProcess();
      try {
        await this.productDiscovery.fetchUserActions({ updateProcess });
      } catch (error) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry && errorInfo.httpStatus !== 404) {
          this.sentry.captureException(error);
        }
      }
    }

    // This should only true when membership.kycSubmitted is false.
    // That's why within the kybPending block we can make the assumption of not showing any banners, as in this case, it would only be replacing kyc banners
    let hasKycUserAction = this.productDiscovery.hasAction('kyc-action');
    let hasKybOnbehalfAction = this.productDiscovery.hasAction('kyb-onbehalf-action');
    let hasKybAssociationAction = this.productDiscovery.hasAction('kyb-association-action');

    if (isKybRefused) {
      // Moved to top-banners/kyc-kyb.js
    } else if (isKybPending) {
      if (hasKycUserAction || hasKybOnbehalfAction || hasKybAssociationAction) {
        // Don't show any banner
      } else if (isKycWaitingDocument) {
        // Moved to top-banners/kyc-kyb.js
      } else if (isKycRefused) {
        // Moved to top-banners/kyc-kyb.js
      } else if (membership.shouldSubmitKyc) {
        // Moved to top-banners/kyc-kyb.js
      } else if (organization.isAssociationKybPending) {
        // Moved to top-banners/kyc-kyb.js
      } else if (organization.isDeCompanyCreation) {
        let message = intl.t('topbar_msg.qonto_kyb_pending_unregistered_DE', messageOptions);
        this.bannerFlashMessages.topBannerInfo(message);
      } else {
        let message = intl.t('topbar_msg.qonto_kyb_pending', messageOptions);
        this.bannerFlashMessages.topBannerInfo(message);
      }
    } else if (!isKycSubmitted && isKycRequired && !hasKycUserAction) {
      this.bannerFlashMessages.topBannerWarning(' ', 'topbar/kyc-missing-id-fourthline');
      return;
    } else if (isKycRefused) {
      let message = intl.t('topbar_msg.qonto_kyc_refused', messageOptions);

      this.bannerFlashMessages.topBannerInfo(message);
    } else if (isKycPending && isKycSubmitted) {
      let message = intl.t('topbar_msg.qonto_kyc_pending', messageOptions);

      this.bannerFlashMessages.topBannerInfo(message);
    } else {
      this.handleKycKybUpdateTopbar();
    }

    this.handleVirtualCardsRenewTopbar();
    this.handlePhysicalCardsRenewTopbar();
    this.handleITFlexKybTopbar();
    this.handleESFlexKybTopbar();
    this.handleDEFreelancerFlexKybTopbar();

    let cbs = currentAccount ? currentAccount.coreBankingSystem : organization.coreBankingSystem;

    let options = {
      locale: this.localeManager.locale,
      cbs,
    };

    // eslint-disable-next-line ember-concurrency/no-perform-without-catch
    this.displayTemporaryAnnouncementTask.perform(options);
  }

  async handleKycKybUpdateTopbar() {
    let { organization } = this.organizationManager;

    if (
      this.hasOngoingKycKybUpdateProcess &&
      organization.legalCountry === 'DE' &&
      safeLocalStorage.getItem(KYCB_UPDATE_BANNER_STORAGE_KEY) !==
        organization.belongsTo('ongoingKycKybUpdateProcess').id()
    ) {
      let updateProcess = await organization.belongsTo('ongoingKycKybUpdateProcess').load();

      if (
        updateProcess.status === 'created' &&
        updateProcess.legallyRequired &&
        (updateProcess.isCompanyReportStatusNotFound || updateProcess.isCompanyReportStatusNull) &&
        updateProcess.createdAt > dayjs().subtract(30, 'day')
      ) {
        this.bannerFlashMessages.topBannerWarning(
          ' ',
          'topbar/kyc-kyb-pending-update',
          KYC_KYB_BANNER_ID,
          {
            updateID: organization.belongsTo('ongoingKycKybUpdateProcess').id(),
          }
        );
      }
    } else {
      this.bannerFlashMessages.removeTopBannerMessage(KYC_KYB_BANNER_ID);
    }
  }

  handlePhysicalCardsRenewTopbar() {
    let count = this.cardCounters?.physical_waiting_to_renew || 0;
    let hasRenewableCards = count > 0;
    if (hasRenewableCards) {
      let message = this.intl.t('topbar_msg.cards.renew.text', { count });
      this.bannerFlashMessages.topBannerWarning(message, 'topbar/cards/physical-renew');
    }
  }

  handleVirtualCardsRenewTopbar() {
    if (!this.cardCounters) {
      return;
    }

    let count = this.cardCounters.virtual_renewal || 0;
    let wasDismissed = Boolean(safeLocalStorage.getItem('virtual-renewed-banner-dismissed'));
    if (count > 0 && !wasDismissed) {
      let message = this.intl.t('topbar_msg.cards.virtual-renew.text', { count });
      this.bannerFlashMessages.topBannerWarning(message, 'topbar/cards/virtual-renew');
    } else if (count === 0 && wasDismissed) {
      safeLocalStorage.removeItem('virtual-renewed-banner-dismissed');
    }
  }

  handleITFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { isFxFlexKybWaiting, legalCountry } = organization;

    if (!isFxFlexKybWaiting || legalCountry !== 'IT') {
      return;
    }

    let message = this.intl.t('it-cc.flex-kyb.topbar-banner.text', {
      link: htmlSafe(
        `<a href=${this.intl.t(
          'it-cc.flex-kyb.topbar-banner.link'
        )} target="_blank" rel="noopener noreferrer">  ${this.intl.t(
          'it-cc.flex-kyb.topbar-banner.link-text'
        )}</a>`
      ),
      htmlSafe: true,
    });
    this.bannerFlashMessages.topBannerInfo(message);
  }

  handleESFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { isFxFlexKybWaiting, legalCountry } = organization;

    if (!isFxFlexKybWaiting || legalCountry !== 'ES') {
      return;
    }

    let message = this.intl.t('es-cc.flex-kyb.topbar-banner.text', {
      htmlSafe: true,
    });
    this.bannerFlashMessages.topBannerInfo(message);
  }

  handleDEFreelancerFlexKybTopbar() {
    let { organization } = this.organizationManager;
    let { flexKyb, isDeFreelancer } = organization;

    let componentName = 'topbar/de-freelancers-flex-kyb';

    if (!flexKyb || flexKyb.status === 'accepted' || !isDeFreelancer) {
      return;
    }

    let hasFlexKybDeFreelancersAction = this.productDiscovery.hasAction(
      'flex-kyb-de-freelancers-action'
    );

    let today = new Date();

    let isInGracePeriod = today > flexKyb.startDate && today <= flexKyb.endDate;
    let isInLast5DaysOfGracePeriod =
      today > dayjs(flexKyb.endDate).subtract(5, 'day').toDate() && today <= flexKyb.endDate;
    let isInDeactivationPeriod = today > flexKyb.endDate && today <= flexKyb.deactivationDueDate;

    if (flexKyb.status === 'waiting') {
      if (isInGracePeriod && !isInLast5DaysOfGracePeriod && !hasFlexKybDeFreelancersAction) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-waiting', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerWarning(message, componentName);
      } else if (isInGracePeriod && isInLast5DaysOfGracePeriod && !hasFlexKybDeFreelancersAction) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-waiting-urgent', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      } else if (isInDeactivationPeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
        let message = this.intl.t('flex-kyb.topbar-banner.closure-period-waiting', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      }
    } else if (flexKyb.status === 'reviewable') {
      let message = this.intl.t('flex-kyb.topbar-banner.reviewable');
      this.bannerFlashMessages.topBannerInfo(message, componentName);
    } else if (flexKyb.status === 'rejected') {
      if (isInGracePeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.endDate);
        let message = this.intl.t('flex-kyb.topbar-banner.30d-period-rejected', {
          count: remainingDays,
          remainingDays,
        });
        if (isInLast5DaysOfGracePeriod) {
          this.bannerFlashMessages.topBannerError(message, componentName);
        } else {
          this.bannerFlashMessages.topBannerWarning(message, componentName);
        }
      } else if (isInDeactivationPeriod) {
        let remainingDays = flexKyb.getRemainingDays(today, flexKyb.deactivationDueDate);
        let message = this.intl.t('flex-kyb.topbar-banner.closure-period-rejected', {
          count: remainingDays,
          remainingDays,
        });
        this.bannerFlashMessages.topBannerError(message, componentName);
      }
    }
  }

  displayTemporaryAnnouncementTask = dropTask(async options => {
    try {
      let messages = await getAnnouncement(options);
      for (let message of messages) {
        this.bannerFlashMessages.topBannerInfo(htmlSafe(message));
      }
      await timeout(THROTTLE_ANNOUNCEMENT_S);
    } catch (error) {
      if (ErrorInfo.for(error).shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }
  });

  get hasOngoingKycKybUpdateProcess() {
    let { organization } = this.organizationManager;

    return Boolean(
      organization.belongsTo('ongoingKycKybUpdateProcess').id() &&
        this.abilities.can('read kyc kyb update process organization') &&
        this.abilities.can('view settings organization') &&
        organization.hasKycKybUpdateFeature
    );
  }
}
