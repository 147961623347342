import Service, { service } from '@ember/service';

import { variation } from 'ember-launch-darkly';

import {
  FRENCH_EINVOICE_PAYMENT_STORAGE_KEY,
  FRENCH_EINVOICING_CONSENT_STORAGE_KEY,
  INVOICE_SOURCES,
  INVOICE_STATUSES,
} from 'qonto/constants/supplier-invoice';
import { USER_ACTIONS_STATUS, USER_ACTIONS_TYPE } from 'qonto/constants/user-actions';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import { ErrorInfo } from 'qonto/utils/error-info';

import { discoveryCards } from './product-discovery/discovery';
import { requiredCards } from './product-discovery/required';
import { setupCards } from './product-discovery/setup';

export default class ProductDiscoveryService extends Service {
  @service abilities;
  @service organizationManager;
  @service subscriptionManager;
  @service periodicUpdate;
  @service intl;
  @service router;
  @service store;
  @service sentry;

  get isProductDiscoverySystemFeatureEnabled() {
    let { organization, membership } = this.organizationManager;
    let { hasProductDiscoverySystemFeature: organizationHasFeature } = organization;
    let { hasProductDiscoverySystemFeature: membershipHasFeature } = membership;
    return organizationHasFeature && membershipHasFeature;
  }

  setupCardConfigs() {
    return setupCards({
      intl: this.intl,
      store: this.store,
      organization: this.organizationManager.organization,
      remainingDaysOfTrial: this.subscriptionManager.currentSubscription?.activeTrialRemainingDays,
    });
  }

  requiredCardConfigs() {
    return requiredCards({ intl: this.intl, organization: this.organizationManager.organization });
  }

  discoveryCardConfigs() {
    return discoveryCards({
      intl: this.intl,
      router: this.router,
      store: this.store,
      organization: this.organizationManager.organization,
    });
  }

  /**
   * Fetches the user-actions using the store.query method. Unloads the records that are not in the response.
   * It also adds custom actions not coming from LaunchDarkly.
   */
  async fetchUserActions({ updateProcess }) {
    let {
      organization: { isSuspended, isDeactivated },
    } = this.organizationManager;

    if (isSuspended || isDeactivated) {
      return;
    }

    let latestRecords = await this.store.query('user-action', {});
    let existingRecords = this.store.peekAll('user-action');
    let staleRecords = existingRecords.filter(
      record => !latestRecords.find(r => r.name === record.name)
    );
    staleRecords.forEach(record => record.unloadRecord());

    // Custom actions coming from the frontend logic
    this.#maybeAddPeriodicUpdateAction({ updateProcess });

    this.#maybeAddFrenchEinvoicingConsentAction();

    this.#maybeAddFrenchFirstSupplierEinvoiceAction();
  }

  #maybeAddPeriodicUpdateAction({ updateProcess }) {
    if (this.periodicUpdate.shouldDisplayPeriodicUpdateAction({ updateProcess })) {
      this.store.createRecord('user-action', {
        name: 'kyc-kyb-periodic-update-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.REQUIRED,
        membership: this.organizationManager.membership,
      });
    }
  }

  async #maybeAddFrenchFirstSupplierEinvoiceAction() {
    let storageKey = JSON.parse(safeLocalStorage.getItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY));

    if (storageKey === null) {
      let today = new Date();
      let todayPlusTwenty = today.setDate(today.getDate() + 20);
      safeLocalStorage.setItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY, todayPlusTwenty);
    }

    let existingRecords = this.store.peekAll('user-action');
    if (
      (await this.shouldShowFrenchFirstSupplierEinvoiceAction()) &&
      !existingRecords.find(r => r.name === 'french-e-invoicing-payement-action')
    ) {
      this.store.createRecord('user-action', {
        name: 'french-e-invoicing-payement-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        membership: this.organizationManager.membership,
      });
    }
  }

  async shouldShowFrenchFirstSupplierEinvoiceAction() {
    let cardExpiredOrDismissed = JSON.parse(
      safeLocalStorage.getItem(FRENCH_EINVOICE_PAYMENT_STORAGE_KEY)
    );
    cardExpiredOrDismissed =
      cardExpiredOrDismissed === null || cardExpiredOrDismissed === false
        ? cardExpiredOrDismissed
        : cardExpiredOrDismissed <= Date.now();

    if (
      !(
        !cardExpiredOrDismissed &&
        this.abilities.can('access supplierInvoice') &&
        this.abilities.can('view supplierInvoice') &&
        variation('feature--boolean-einvoicing-q2q') &&
        this.organizationManager.organization.legalCountry === 'FR'
      )
    ) {
      return false;
    }

    let supplierEinvoiceCompleted = [];
    let supplierEinvoiceUncompleted = [];

    try {
      supplierEinvoiceCompleted = await this.store.query('supplier-invoice', {
        page: 1,
        per_page: 25,
        filter: {
          status: [INVOICE_STATUSES.scheduled, INVOICE_STATUSES.paid, INVOICE_STATUSES.archived],
          source: [INVOICE_SOURCES.eInvoicing],
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    if (supplierEinvoiceCompleted.length > 0) {
      return false;
    }

    supplierEinvoiceUncompleted = await this.getFrenchSupplierEinvoices();

    return supplierEinvoiceUncompleted.length > 0;
  }

  async getFrenchSupplierEinvoices() {
    let supplierEinvoiceUncompleted = [];

    try {
      supplierEinvoiceUncompleted = await this.store.query('supplier-invoice', {
        page: 1,
        per_page: 25,
        filter: {
          status: [INVOICE_STATUSES.toReview, INVOICE_STATUSES.toPay],
          source: [INVOICE_SOURCES.eInvoicing],
        },
      });
    } catch (error) {
      let errorInfo = ErrorInfo.for(error);
      if (errorInfo.shouldSendToSentry) {
        this.sentry.captureException(error);
      }
    }

    return supplierEinvoiceUncompleted;
  }

  async shouldShowFrenchEinvoicingConsentAction() {
    let orga = this.organizationManager.organization;

    let daysInSeconds = 24 * 60 * 60 * 1000;
    let today = new Date();
    let contractSignedAtInDays = orga?.contractSignedAt
      ? Math.round((today.getTime() - orga.contractSignedAt.getTime()) / daysInSeconds)
      : 0;
    let minimunDaysContractedSigned = 30;

    let einvoiceAbility = this.abilities.can('read einvoicingSetting');

    let cardExpiredOrDismissed = JSON.parse(
      safeLocalStorage.getItem(FRENCH_EINVOICING_CONSENT_STORAGE_KEY)
    );
    cardExpiredOrDismissed =
      cardExpiredOrDismissed === null || cardExpiredOrDismissed === false
        ? cardExpiredOrDismissed
        : cardExpiredOrDismissed <= Date.now();

    if (
      !(
        !cardExpiredOrDismissed &&
        variation('feature--boolean-einvoicing-q2q') &&
        einvoiceAbility &&
        orga?.kybStatus === 'accepted' &&
        contractSignedAtInDays >= minimunDaysContractedSigned &&
        orga.legalCountry === 'FR'
      )
    ) {
      return false;
    }

    let frenchEinvoicingSetting;
    try {
      frenchEinvoicingSetting = await this.store.findRecord(
        'einvoicing-settings',
        this.organizationManager.organization.id
      );
    } catch (error) {
      if (error.status !== 404) {
        let errorInfo = ErrorInfo.for(error);
        if (errorInfo.shouldSendToSentry) {
          this.sentry.captureException(error);
        }
      }
      return false;
    }

    return (
      frenchEinvoicingSetting?.einvoicingOnboarded &&
      !frenchEinvoicingSetting?.einvoicingConsentGiven
    );
  }

  async #maybeAddFrenchEinvoicingConsentAction() {
    let storageKey = JSON.parse(safeLocalStorage.getItem(FRENCH_EINVOICING_CONSENT_STORAGE_KEY));

    if (storageKey === null) {
      let today = new Date();
      let todayPlusTwenty = today.setDate(today.getDate() + 20);
      safeLocalStorage.setItem(FRENCH_EINVOICING_CONSENT_STORAGE_KEY, todayPlusTwenty);
    }

    let existingRecords = this.store.peekAll('user-action');
    if (
      (await this.shouldShowFrenchEinvoicingConsentAction()) &&
      !existingRecords.find(r => r.name === 'french-e-invoicing-consent-action')
    ) {
      this.store.createRecord('user-action', {
        name: 'french-e-invoicing-consent-action',
        status: USER_ACTIONS_STATUS.ENABLED,
        type: USER_ACTIONS_TYPE.DISCOVERY,
        membership: this.organizationManager.membership,
      });
    }
  }

  getCardConfigFn(type, name) {
    if (type === USER_ACTIONS_TYPE.SETUP) {
      return this.setupCardConfigs()[name];
    } else if (type === USER_ACTIONS_TYPE.REQUIRED) {
      return this.requiredCardConfigs()[name];
    } else if (type === USER_ACTIONS_TYPE.DISCOVERY) {
      return this.discoveryCardConfigs()[name];
    }
  }

  visibleUserActions() {
    return this.store
      .peekAll('user-action')
      .filter(({ status }) => status !== USER_ACTIONS_STATUS.DISMISSED)
      .filter(({ membership: { id } }) => id === this.organizationManager.membership.id)
      .reduce((acc, actionRecord) => {
        let cardConfigFn = this.getCardConfigFn(actionRecord.type, actionRecord.name);
        if (!cardConfigFn) {
          this.sentry.captureMessage(
            `Product Discovery - User action with name: "${actionRecord.name}" and type: "${actionRecord.type}" does not exist in qonto-js`
          );
          return acc;
        } else {
          let cardConfig = cardConfigFn();
          return [
            ...acc,
            {
              ...cardConfig,
              name: actionRecord.name,
              type: actionRecord.type,
              status: actionRecord.status,
              actionRecord,
            },
          ];
        }
      }, []);
  }

  hasAction(name) {
    return Boolean(this.visibleUserActions().find(action => action.name === name));
  }
}
