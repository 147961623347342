// @ts-nocheck
import { ForbiddenError } from '@ember-data/adapter/error';
import { get } from '@ember/object';
import Service, { type Registry as Services, service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { isTesting, macroCondition } from '@embroider/macros';
import * as Sentry from '@sentry/ember';
import { all, dropTask, timeout } from 'ember-concurrency';
import window from 'ember-window-mock';
import { reads } from 'macro-decorators';
import { ignoreCancelation } from 'qonto/utils/ignore-error';

import {
  companyCreationJsURL,
  registerBaseURL,
  registerJsURL,
  registerNamespace,
  registerPartnersJsURL,
} from 'qonto/constants/hosts';
import { safeLocalStorage } from 'qonto/helpers/safe-local-storage';
import type MembershipModel from 'qonto/models/membership';
import type Organization from 'qonto/models/organization';

export default class OrganizationManager extends Service {
  @service featuresManager;
  @service toastFlashMessages;
  @service intl;
  @service networkManager;
  @service notifierManager;
  @service router;
  @service declare sessionManager: Services['sessionManager'];
  @service store;
  @service userManager;
  @service sentry;
  @service launchdarkly;
  @service banners;
  @service zendeskWidget;
  @service zendeskLocalization;
  @service abilities;
  @service categoriesManager;
  @service tandem;

  @tracked isDataLoaded = false;
  @tracked organization: Organization = null;
  @tracked membership: MembershipModel = null;
  @tracked registeringOrganizations = null;
  @tracked companyCreationOrganizations = null;

  constructor() {
    super(...arguments);

    this.notifierManager.on(
      'organization.contract_signed_registered',
      this,
      'loadOrganizationAndMemberships'
    );
    this.notifierManager.on(
      'organization.contract_signed_unregistered',
      this,
      'loadOrganizationAndMemberships'
    );
  }

  willDestroy() {
    this.notifierManager.off(
      'organization.contract_signed_registered',
      this,
      'loadOrganizationAndMemberships'
    );
    this.notifierManager.off(
      'organization.contract_signed_unregistered',
      this,
      'loadOrganizationAndMemberships'
    );
  }

  get accounts() {
    return this.organization.bankAccounts || [];
  }

  get isKycKybAccepted() {
    return this.organization?.kybAccepted && this.membership?.kycAccepted;
  }

  @reads('organization.primaryAccount') currentAccount;

  /**
   * It is called to populate the orgnization-manager
   * with the user's organizations, after the login
   * and after a page refresh.
   *
   * @public
   * @method setupTask
   *
   * @returns {Promise}
   */
  setupTask = dropTask(async ({ checkForPendingInvite = false } = {}) => {
    let [organizations, companyCreationOrganizations, partnerOrganizations] = await all([
      this.loadOrganizationAndMemberships(),
      this.loadCompanyCreationOrganizations(),
      this.loadPartnerOrganizations(),
    ]);

    this.organizations = organizations;
    this.companyCreationOrganizations = companyCreationOrganizations;
    this.partnerOrganizations = partnerOrganizations;
    this.isDataLoaded = true;

    if (!this.organizations.length) {
      let pendingInvites = checkForPendingInvite
        ? await this.store.query('invite', { email: this.userManager.currentUser.email })
        : [];

      if (pendingInvites.length > 0) {
        this.toastFlashMessages.toastError(
          this.intl.t('toasts.errors.resume-invitation-from-email')
        );

        await timeout(macroCondition(isTesting()) ? 0 : 1000);

        return this.sessionManager.invalidate();
      } else if (this.partnerOrganizations.length) {
        window.location.replace(registerPartnersJsURL);
      } else if (this.companyCreationOrganizations.length) {
        window.location.replace(companyCreationJsURL);
      } else {
        // We do not have API organizations, partner-organizations or company-creation organizations so we assume that we have at least one registering organization
        window.location.replace(registerJsURL);
      }
    }
  });

  /**
   * This method is a helper used to pick an organization among the organizations
   * the user has an account for.
   *
   * @public
   * @method getDefaultOrganization
   *
   * @returns {Object} | {Null} Default organization
   */
  getDefaultOrganization() {
    if (!this.organizations || !this.organizations.length) {
      return null;
    }

    let allowedOrgas = this.organizations.filter(({ accessDisabled }) => accessDisabled === false);

    if (safeLocalStorage.getItem('organizationId')) {
      let lastVisitedOrga = allowedOrgas.find(
        item => get(item, 'id') === safeLocalStorage.getItem('organizationId')
      );
      if (lastVisitedOrga) {
        return lastVisitedOrga;
      }
    }

    return allowedOrgas[0] || this.organizations[0];
  }

  /**
   * Retreive an organization by its slug
   *
   * @public
   * @method getOrganizationBySlug
   * @param  {String} slug Slug of the organization
   * @returns {Object} | {Null} Organization
   */
  getOrganizationBySlug(slug) {
    if (!this.organizations || this.organizations.length === 0) {
      this.sentry.captureMessage('getOrganizationBySlug: organizations not loaded');
      return null;
    }

    return this.organizations.find(item => get(item, 'slug') === slug);
  }

  /**
   * Choose and set a new organization
   * Make a copy in the localStorage
   *
   * @public
   * @method setCurrentOrganizationAndMembership
   * @param  {Object} organization Organization to set
   * @returns void
   */
  async setCurrentOrganizationAndMembership(organization) {
    Sentry.configureScope(scope => scope.setTag('organization_id', organization.id));
    Sentry.configureScope(scope =>
      scope.setTag('organization status', organization.status || null)
    );

    let currentMembership = this.findCurrentMembership(organization);

    if (!currentMembership) {
      this.sentry.captureMessage('setCurrentOrganizationAndMembership: membership not found');
      return;
    }

    let membershipId = currentMembership.id;
    this.organization = organization;

    let [membership] = await Promise.all([
      this.store.findRecord('membership', membershipId),
      this.launchdarkly.isInitialized
        ? this.launchdarkly.identify({
            userId: this.userManager.currentUser.id,
            customData: {
              organization_organizationId: organization.id,
              organization_organizationName: organization.legalName,
              organization_legalCountry: organization.legalCountry,
              organization_contractSignedAt: organization.contractSignedAt?.getTime(),
              organization_legalSector: organization.legalSector || '',
              membership_membershipId: membershipId,
            },
          })
        : null,
    ]);

    this.membership = membership;

    this.featuresManager.setup([...organization.features, ...membership.features]);

    safeLocalStorage.setItem('organizationId', organization.get('id'));

    await this.loadCashFlowCategories(organization.id);

    await this.zendeskWidget.selectCountry(organization.legalCountry);
    await this.tandem.mount();
    this.zendeskLocalization.organizationCountry = organization.legalCountry;
  }

  findCurrentMembership(organization) {
    return organization
      .get('memberships')
      .find(item => get(item, 'user.id') === this.userManager.currentUser.id);
  }

  /**
   * Load the organization and memberships associated
   *
   * @public
   * @method loadOrganizationAndMemberships
   * @returns {Promise}
   */
  async loadOrganizationAndMemberships() {
    let organizations = [];

    try {
      organizations = await this.store.query('organization', {
        // update query method for something more accurate
        includes: ['memberships'],
        with: ['label_lists', 'labels'],
        per_page: 300,
      });
    } catch (error) {
      /**
       * Bypass rejection if the error code is 403
       * API should return 200 with an empty collection instead
       * This also allow this function to be used in a `Promise.all`
       * without cancelling other functions incorrectly
       */
      if (!(error instanceof ForbiddenError)) {
        throw error;
      }
    }
    return organizations;
  }

  loadCompanyCreationOrganizations() {
    return this.store.findAll('ccOrganization');
  }

  loadPartnerOrganizations() {
    return this.store.query('partnerOrganization', {
      filter: {
        registration_status_eq: 'pending',
        registration_flow_type_eq: 'seamless',
      },
      include: 'registration',
      fields: {
        registrations: 'status',
        organizations: 'legal_name,registration',
      },
    });
  }

  /**
   * Make a call to find memberships of an organization
   *
   * @public
   * @method findMembers
   *
   * @param  {Object} query Query to pass to the request
   * @param  {Object} filters Filters to pass to the request
   * @returns {Promise}
   */
  async findMembers(query, filters) {
    return await this.store.query('membership', {
      // Update query method to something more accurate
      organization_id: this.get('organization.id'),
      query,
      filters,
      per_page: 200,
    });
  }

  /**
   * Update a membership with onboarded flag to true
   * and make a call to save it
   *
   * @public
   * @method flagMembershipOnboarded
   *
   * @returns {Promise}
   */
  async flagMembershipOnboarded() {
    this.membership.onboardedCard = true;
    await this.get('membership').save();
  }

  /**
   * Load organizations still in registering process
   *
   * @public
   * @method loadRegisteringOrganizations
   *
   * @returns {Promise}
   */
  async loadRegisteringOrganizations() {
    let url = `${registerBaseURL}/${registerNamespace}/organizations`;
    let data = await this.networkManager.request(url);
    this.registeringOrganizations = data.organizations;
  }

  async updateBankAccounts() {
    await this.organization.loadBankAccounts();
  }

  async loadCashFlowCategories(organizationId) {
    if (this.abilities.can('assign category')) {
      await this.categoriesManager.fetchCategoriesTask
        .perform(organizationId)
        .catch(ignoreCancelation);
    }
  }
}

declare module '@ember/service' {
  interface Registry {
    'organization-manager': OrganizationManager;
  }
}
