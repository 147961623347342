import type { LocaleCode } from '@repo/shared-config/constants/locales';
import type { Currency } from 'qonto/services/international-out/types';

export interface EnhancedCurrency extends Currency {
  /**
   * The display name of the currency (e.g. 'US Dollar').
   */
  displayName?: string;

  /**
   * An array of keywords associated with the currency for search and suggestion purposes.
   */
  keywords: string[];

  /**
   * A flag indicating whether the currency is new.
   */
  isNew: boolean;
}

/**
 * Creates a currency object based on the given currency code.
 *
 * @param currencyCode - The currency code to create the object for.
 * @returns An object representing a currency, which includes the currency code, a derived country code, and a `null` suggestion priority.
 * @throws If the currency code is not exactly 3 characters long.
 */
export function createCurrency(currencyCode: string): Currency {
  if (!/^[A-Za-z]{3}$/.test(currencyCode)) {
    throw new Error(
      `Invalid currency code (${currencyCode}). It must be exactly 3 letters (ISO 4217 standard).`
    );
  }

  let code = currencyCode.toUpperCase();

  return {
    code,
    countryCode: code.slice(0, -1),
    suggestionPriority: null,
  };
}

/**
 * Extends currency object with additional properties such as:
 * - currency display name
 * - keywords list
 * - new currency flag
 *
 * @param params - The params object.
 * @param params.currency - The currency object.
 * @param params.currency.code - The currency code.
 * @param params.currency.countryCode - The country code.
 * @param params.currency.rest - Additional properties of the currency object.
 * @param params.options - The options object.
 * @param params.options.locale - The locale used for localization.
 * @param params.options.newCurrenciesList - A list of new currency codes.
 * @returns An extended currency object.
 *
 * @example
 *
 * ```ts
 * const extendedCurrency = extendCurrency({
 *   currency: {
 *     code: 'USD',
 *     countryCode: 'US',
 *     ...
 *   },
 *   options: {
 *     locale: 'en',
 *     newCurrenciesList: ['USD']
 *   }
 * });
 * // {
 * //   code: 'USD',
 * //   countryCode: 'US',
 * //   displayName: 'US Dollar',
 * //   keywords: ['USD', 'US', 'US Dollar'],
 * //   isNew: true,
 * //   ...
 * // }
 * ```
 */
function extendCurrency<T extends Currency>({
  currency,
  options: { locale = 'en', newCurrenciesList = [] },
}: {
  currency: T;
  options: {
    locale: LocaleCode;
    newCurrenciesList: string[];
  };
}): T & EnhancedCurrency {
  const { code, countryCode } = currency;
  const displayName = getFullCurrencyName({ currencyCode: code, locale });
  const keywords = [...new Set([code, countryCode, ...(displayName ? [displayName] : [])])];
  const isNew = newCurrenciesList.includes(code);

  return {
    ...currency,
    code,
    displayName,
    countryCode,
    keywords,
    isNew,
  };
}

/**
 * Retrieves the number of allowed decimal places for a given currency code.
 *
 * @param currencyCode - The currency code (e.g. 'USD', 'EUR').
 * @returns The number of allowed decimal places for the specified currency.
 *
 * @example
 *
 * ```ts
 * getAllowedDecimalPlaces('JPY'); // Returns 0 (no decimal places for JPY)
 * getAllowedDecimalPlaces('USD'); // Returns 2 (default for USD)
 * getAllowedDecimalPlaces('BHD'); // Returns 3 (default for BHD)
 * ```
 */
function getAllowedDecimalPlaces(currencyCode: string): number {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currencyCode,
  });

  return formatter.resolvedOptions().minimumFractionDigits || 0;
}

/**
 * Calculates the corresponding amount interval for a given currency code.
 *
 * @param currencyCode - The currency code for which to calculate the corresponding amount interval.
 * @returns The corresponding amount interval for the specified currency code.
 *
 * @example
 *
 * ```ts
 * getCorrespondingAmountInterval('JPY'); // Returns 1 (no decimal places for JPY)
 * getCorrespondingAmountInterval('USD'); // Returns 0.01 (default for USD)
 * getCorrespondingAmountInterval('BHD'); // Returns 0.001 (default for BHD)
 * ```
 */
function getCorrespondingAmountInterval(currencyCode: string): number {
  return Math.pow(10, -getAllowedDecimalPlaces(currencyCode));
}

/**
 * Generates a placeholder string representing a zero amount with appropriate decimal places based on currency code.
 *
 * @param currencyCode - The currency code for which the placeholder is generated.
 * @param decimalPoint - The character used as the decimal point.
 * @returns A placeholder string representing a zero amount with appropriate decimal places.
 *
 * @example
 *
 * ```ts
 * getCorrespondingAmountPlaceholder('JPY'); // Returns '0'
 * getCorrespondingAmountPlaceholder('USD'); // Returns '0,00'
 * getCorrespondingAmountPlaceholder('BHD'); // Returns '0,000'
 * getCorrespondingAmountPlaceholder('USD', '.'); // Returns '0.00'
 * ```
 */
function getCorrespondingAmountPlaceholder(currencyCode: string, decimalPoint = ','): string {
  const numberOfDecimals = getAllowedDecimalPlaces(currencyCode);
  return '0' + (numberOfDecimals > 0 ? decimalPoint + '0'.repeat(numberOfDecimals) : '');
}

/**
 * Retrieves the full currency name corresponding to the given currency code and locale.
 *
 * @param options - The options object.
 * @param options.currencyCode - The currency code to get the full name for.
 * @param options.locale - The locale used for formatting (e.g. 'en-US', 'fr-FR').
 * @returns The full name of the currency corresponding to the provided code and locale.
 *
 * @example
 *
 * ```ts
 * getFullCurrencyName({
 *   currencyCode: 'USD'
 *   locale: 'en-US'
 * }); // Returns 'US Dollar'
 * ```
 */
function getFullCurrencyName({
  currencyCode,
  locale,
}: {
  currencyCode: string;
  locale: LocaleCode;
}): string | undefined {
  return new Intl.DisplayNames(locale, { type: 'currency' }).of(currencyCode);
}

/**
 * Groups currencies into two arrays based on their suggestion priority.
 * Currencies with a `suggestionPriority` property are considered popular and sorted accordingly.
 * Currencies without a `suggestionPriority` property are grouped separately.
 *
 * @param currencies - An array of currency objects.
 * @returns An array containing two arrays: the first being the popular currencies and the other one being the rest of the currencies.
 *
 * @example
 *
 * ```ts
 * const currencies = [
 *   { currencyCode: 'USD', ..., suggestionPriority: 1 },
 *   { currencyCode: 'EUR', ..., suggestionPriority: 2 },
 *   { currencyCode: 'GBP', ..., suggestionPriority: null },
 *   { currencyCode: 'JPY', ..., suggestionPriority: 3 },
 *   { currencyCode: 'AED' },
 * ];
 * const [popular, others] = groupCurrencies(currencies);
 * console.log(popular); // [{ currencyCode: 'JPY', ..., suggestionPriority: 3 }, { currencyCode: 'EUR', ..., suggestionPriority: 2 }, { currencyCode: 'USD', ..., suggestionPriority: 1 }]
 * console.log(others); // [{ currencyCode: 'GBP', ..., suggestionPriority: null }, { currencyCode: 'AED' },]
 * ```
 */
function groupCurrencies(currencies: Currency[]): Currency[][] {
  const popularCurrencies = currencies.filter(
    currency =>
      currency.hasOwnProperty('suggestionPriority') &&
      currency.suggestionPriority !== null &&
      typeof currency.suggestionPriority === 'number'
  );

  popularCurrencies.sort(
    // @ts-ignore: type narrowing issues on `Array` methods - it would be fixed with `typescript@5.5`
    (currency, otherCurrency) => otherCurrency.suggestionPriority - currency.suggestionPriority
  );

  const otherCurrencies = currencies.filter(currency => !popularCurrencies.includes(currency));

  return [popularCurrencies, otherCurrencies];
}

export {
  extendCurrency,
  getAllowedDecimalPlaces,
  getCorrespondingAmountInterval,
  getCorrespondingAmountPlaceholder,
  getFullCurrencyName,
  groupCurrencies,
};
