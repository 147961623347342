import Service, { service } from '@ember/service';
import { decamelize } from '@ember/string';

import { variation } from 'ember-launch-darkly';

import { apiBaseURL, internationalOutNamespace } from 'qonto/constants/hosts';
import { ELIGIBILITY_STATUS } from 'qonto/constants/international-out/eligibility';
import { FEATURES } from 'qonto/constants/international-out/feature';
import { DEFAULT_SOURCE_CURRENCY_CODE } from 'qonto/constants/international-out/quote';
import {
  normalizeConfirmation,
  normalizeCurrency,
  normalizeFee,
  normalizeQuote,
} from 'qonto/utils/international-out/normalize';
import removeNullValues from 'qonto/utils/remove-null-values';
import transformKeys from 'qonto/utils/transform-keys';

/**
 * @typedef {import('./international-out/types').Eligibility} Eligibility
 */

/**
 * @typedef {import('./international-out/types').Amount} Amount
 */

/**
 * @typedef {import('./international-out/types').Currency} Currency
 */

/**
 * @typedef {import('./international-out/types').Quote} Quote
 */

/**
 * @typedef {import('./international-out/types').Beneficiary} Beneficiary
 */

/**
 * @typedef {import('./international-out/types').Field} Field
 */

/**
 * @typedef {import('./international-out/types').FieldsGroup} FieldsGroup
 */

/**
 * @typedef {import('./international-out/types').Requirements} Requirements
 */

/**
 * @typedef {import('./international-out/types').Transfer} Transfer
 */

/**
 * @typedef {import('./international-out/types').Fees} Fees
 */

/**
 * @typedef {import('./international-out/types').Spendings} Spendings
 */

/**
 * @typedef {import('./international-out/types').Confirmation} Confirmation
 */

/**
 * @typedef {import('./international-out/types').ProcessingEvent} ProcessingEvent
 */

/**
 * Provides methods to interact with the outgoing international transfer API.
 *
 * @public
 * @class InternationalOutManager
 * @extends Service
 * @module services/international-out-manager
 */
export default class InternationalOutManager extends Service {
  @service networkManager;
  @service organizationManager;

  /**
   * Eligibility status for outgoing international transfers.
   *
   * @private
   * @type {Eligibility|null}
   * @default null
   */
  #eligibilityStatus = null;

  /**
   * Unique identifier used to ensure idempotency.
   *
   * @private
   * @type {string|null}
   * @default null
   */
  #idempotencyKey = null;

  /**
   * Generates a unique idempotency key using the randomUUID() method of the Crypto interface and assigns it to the service.
   *
   * @public
   * @function addIdempotencyKey
   * @returns {void}
   */
  addIdempotencyKey() {
    this.#idempotencyKey = crypto.randomUUID();
  }

  /**
   * Gets the idempotency key.
   *
   * @public
   * @function getIdempotencyKey
   * @returns {string|null} The idempotency key.
   */
  getIdempotencyKey() {
    return this.#idempotencyKey;
  }

  /**
   * Removes the idempotency key from the service by setting it to null.
   *
   * @public
   * @function removeIdempotencyKey
   * @returns {void}
   */
  removeIdempotencyKey() {
    this.#idempotencyKey = null;
  }

  /**
   * Adds the idempotency key as a header to the network manager.
   *
   * @public
   * @function addIdempotencyHeader
   * @returns {void}
   */
  addIdempotencyHeader() {
    this.networkManager.addIdempotencyHeader(this.#idempotencyKey);
  }

  /**
   * Removes the idempotency key from the headers of the network manager.
   *
   * @public
   * @function removeIdempotencyHeader
   * @returns {void}
   */
  removeIdempotencyHeader() {
    this.networkManager.removeIdempotencyHeader();
  }

  /**
   * Confirms a transfer.
   *
   * @public
   * @async
   * @function confirmTransfer
   * @param {Object} params - The parameters object.
   * @param {Amount} params.amount - The total source amount (value and currency code), including the transfer fees.
   * @param {string} params.bankAccountId - The ID of the bank account.
   * @param {string} params.beneficiaryId - The ID of the beneficiary.
   * @returns {Promise<Confirmation>} A promise that resolves to the confirmation response.
   * @throws If the network request fails or if the response is invalid.
   */
  async confirmTransfer({ amount, bankAccountId, beneficiaryId }) {
    let url = this.#buildURL('confirm');
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        payload: transformKeys(
          {
            amount: {
              currency: amount.currency,
              value: amount.value.toString(),
            },
            bankAccountId,
            beneficiaryId,
            organizationId: this.organizationManager.organization.id,
          },
          decamelize
        ),
      }),
    });
    return normalizeConfirmation(transformKeys(response));
  }

  /**
   * Creates a new beneficiary.
   *
   * @public
   * @async
   * @function createBeneficiary
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.currency - The currency (ISO 4217 Alphabetic Code) to associate the beneficiary with.
   * @param {string} params.type - The payment type to associate the beneficiary with.
   * @param {Object} params.details - The attributes to create the beneficiary with.
   * @returns {Promise<{ beneficiary: Beneficiary, fees: Fees, quote: Quote, targetAccountId: number }>} A promise that resolves to an object containing the patched quote, the newly created beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   */
  async createBeneficiary({ quoteId, currency, type, details }) {
    let url = this.#buildURL(`beneficiaries`);
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    let { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * Creates the quote resource required to create an internatinal transfer.
   * The quote contains useful information such as the exchange rate, the estimated delivery time and the methods the user can pay for the transfer.
   *
   * @public
   * @async
   * @function createQuote
   * @param {Object} data - The data object containing amount and currency information to use as a payload for the request.
   * @param {number|null} data.targetAmount - The target amount.
   * @param {number|null} data.sourceAmount - The source amount.
   * @param {string} data.targetCurrency - The target currency (ISO 4217 Alphabetic Code).
   * @param {string} data.sourceCurrency - The source currency (ISO 4217 Alphabetic Code).
   * @returns {Promise<{ fees: Fees, quote: Quote }>} A promise that resolves to an object containing the fees and quote objects.
   * @throws If the network request fails or if the response is invalid.
   */
  async createQuote(data) {
    let url = this.#buildURL('quote');
    let response = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify(data),
    });
    let { fees, quote } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote) };
  }

  /**
   * Creates the transfer (i.e. payment order) to the target account based on the quote resource.
   * Once created, the transfer needs to be funded. Otherwise, it will be automatically canceled.
   *
   * @public
   * @async
   * @function createTransfer
   * @param {Object} params - The parameters object.
   * @param {Array<string>} [params.attachmentIds=[]] - A list of attachment IDs.
   * @param {string} params.bankAccountId - The ID of the bank account.
   * @param {string} params.beneficiaryId - The ID of the beneficiary stored by Qonto.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {number} params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param {Object} params.details - The transfer details.
   * @param {Amount} params.sourceAmount - The source amount (value and currency code), including the transfer fees.
   * @param {Amount} params.targetAmount - The target amount (value and currency code).
   * @returns {Promise<Transfer>} A promise that resolves to the transfer object.
   * @throws If the network request fails or if the response is invalid.
   */
  async createTransfer({
    attachmentIds = [],
    bankAccountId,
    beneficiaryId,
    quoteId,
    targetAccountId,
    details,
    sourceAmount,
    targetAmount,
  }) {
    let url = this.#buildURL('transfers');
    let { transfer } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        transfer: {
          attachmentIds,
          bankAccountId,
          beneficiaryId,
          quoteId,
          targetAccountId,
          details,
          totalSourceAmount: {
            currency: sourceAmount.currency,
            value: sourceAmount.value.toString(),
          },
          targetAmount: {
            currency: targetAmount.currency,
            value: targetAmount.value.toString(),
          },
        },
      }),
    });
    return transformKeys(transfer);
  }

  /**
   * Deletes a beneficiary.
   *
   * @public
   * @async
   * @function deleteBeneficiary
   * @param {Beneficiary} beneficiary - The beneficiary to delete.
   * @returns {Promise<void>}
   * @throws If the network request fails or if the response is invalid.
   */
  async deleteBeneficiary({ id }) {
    let url = this.#buildURL(`beneficiaries/${id}`);
    await this.networkManager.request(url, {
      method: 'DELETE',
    });
  }

  /**
   * Retrieves the additional beneficiary requirements for a given quote and beneficiary.
   * Are returned only the requirements that are not explicitely marked as optional.
   *
   * @public
   * @async
   * @function getAdditionalBeneficiaryRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {Object} params.beneficiary - The beneficiary object to get the additional requirements for.
   * @param {string} params.beneficiary.id - The ID of the selected beneficiary.
   * @param {string} params.beneficiary.currency - The currency of the selected beneficiary.
   * @param {string} params.beneficiary.paymentType - The payment type of the selected beneficiary.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the additional beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getAdditionalBeneficiaryRequirements({ quoteId, beneficiary, details = {} }) {
    let additionalRequirements = await this.getBeneficiaryRequirements({
      quoteId,
      currency: beneficiary.currency,
      beneficiary,
      details,
    });

    let requiredAdditionalRequirements = additionalRequirements
      ?.map(accountType => ({
        ...accountType,
        fields: accountType.fields
          .map(({ group }) => group)
          .flat()
          .filter(({ required }) => required),
      }))
      .filter(accountType => accountType.fields.length > 0);

    return additionalRequirements?.filter(first =>
      requiredAdditionalRequirements.some(second => first.type === second.type)
    );
  }

  /**
   * Retrieves a list of beneficiaries based on the provided currency.
   *
   * @public
   * @async
   * @function getBeneficiaries
   * @param {string} currency - The currency (ISO 4217 Alphabetic Code) for which to retrieve beneficiaries.
   * @returns {Promise<Array<Beneficiary>>} A Promise that resolves to an array of beneficiary objects.
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaries(currency) {
    let url = this.#buildURL('beneficiaries', { currency });
    let { beneficiaries } = await this.networkManager.request(url);
    return transformKeys(beneficiaries);
  }

  /**
   * Gets the account details of a beneficiary.
   *
   * @async
   * @function getBeneficiaryDetails
   * @param {string} beneficiaryId - The ID of the beneficiary.
   * @returns {Promise<Array<{ key: string; title: string; value: string; }>>}
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaryDetails(beneficiaryId) {
    let url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    let { localizedData } = await this.networkManager.request(url);
    return transformKeys(localizedData);
  }

  /**
   * Retrieves the beneficiary requirements for a given quote (and an optional beneficiary).
   * The data returned can be used to build a dynamic user interface for beneficiary creation or edition,
   * as it helps figuring out which fields are required to create a valid beneficiary for different currencies.
   *
   * @public
   * @async
   * @function getBeneficiaryRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.currency - The target currency (ISO 4217 Alphabetic Code).
   * @param {Object} [params.beneficiary] - The beneficiary object to refresh the requirements for.
   * @param {string} [params.beneficiary.id] - The ID of the selected beneficiary.
   * @param {string} [params.beneficiary.paymentType] - The payment type of the selected beneficiary.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the beneficiary requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getBeneficiaryRequirements({ quoteId, currency, beneficiary = {}, details = {} }) {
    let { id, paymentType: type } = beneficiary;
    let url = this.#buildURL('beneficiary-requirements');
    let { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        ...(id && { beneficiaryId: id }),
        payload: {
          ...(type && { type }),
          currency,
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * Fetches eligibility information regarding outgoing international transfers.
   *
   * @public
   * @async
   * @function getEligibilityStatus
   * @returns {Promise<Eligibility>} A promise that resolves the eligibility information.
   * @throws If the network request fails or if the response is invalid.
   */
  async getEligibilityStatus() {
    let url = this.#buildURL('eligibility');
    let eligibilityStatus = await this.networkManager.request(url);
    this.#eligibilityStatus = transformKeys(eligibilityStatus);
    return this.#eligibilityStatus;
  }

  /**
   * Retrieves the last available eligibility information regarding outgoing international transfers.
   * It fetches it if it has not been fetched yet. Otherwise, it returns the cached value.
   *
   * @public
   * @async
   * @function getLastEligibilityStatus
   * @returns {Promise<Eligibility>} A promise that resolves the eligibility information.
   * @throws If the network request fails or if the response is invalid.
   */
  async getLastEligibilityStatus() {
    return this.#eligibilityStatus ?? (await this.getEligibilityStatus());
  }

  /**
   * Fetches available target currencies based on the provided source currency.
   *
   * @public
   * @async
   * @function getTargetCurrencies
   * @param {string} [sourceCurrency="EUR"] - The source currency (ISO 4217 Alphabetic Code).
   * @returns {Promise<Array<Currency>>} A Promise that resolves to an array containing the available target currencies for the given currency code.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTargetCurrencies(sourceCurrency = DEFAULT_SOURCE_CURRENCY_CODE) {
    let url = this.#buildURL('currencies', {
      source: sourceCurrency,
      ...(variation('feature--boolean-international-out-eur-to-eur') && {
        enabledFeatures: [FEATURES.EUR_TARGET],
      }),
    });
    let { currencies } = await this.networkManager.request(url);
    return transformKeys(currencies).map(normalizeCurrency);
  }

  /**
   * Retrieves the transfer requirements for a given quote.
   * The data returned can be used to build a dynamic user interface for transfer creation,
   * as it helps figuring out which fields are required to create a valid transfer.
   *
   * @public
   * @async
   * @function getTransferRequirements
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {number} params.targetAccountId - The ID of the beneficiary stored by the outgoing international transfer provider.
   * @param {Object} [params.details] - The details containing the form data.
   * @returns {Promise<Requirements>} A promise that resolves to the transfer requirements.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTransferRequirements({ quoteId, targetAccountId, details = {} }) {
    let url = this.#buildURL('transfer-requirements');
    let { requirements } = await this.networkManager.request(url, {
      method: 'POST',
      data: JSON.stringify({
        quoteId,
        targetAccountId,
        payload: {
          details: removeNullValues(details),
        },
      }),
    });
    return transformKeys(requirements);
  }

  /**
   * Retrieves the transfer timeline for a given transfer ID.
   * The timeline represents the chain of events of the processing transfer (creation, validation, shipment, completion).
   *
   * @public
   * @async
   * @function getTransferTimeline
   * @param {string} transferId - The ID of the transfer.
   * @returns {Promise<Array<ProcessingEvent>>} A promise that resolves to the transfer timeline.
   * @throws If the network request fails or if the response is invalid.
   */
  async getTransferTimeline(transferId) {
    let url = this.#buildURL(`transfers/${transferId}/timeline`);
    let { timeline } = await this.networkManager.request(url);
    return transformKeys(timeline);
  }

  /**
   * Checks if the current organization is eligible for outgoing international transfers.
   *
   * @public
   * @async
   * @function isEligible
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the entity is eligible for outgoing international transfers.
   */
  async isEligible() {
    try {
      let { status } = await this.getLastEligibilityStatus();
      return status === ELIGIBILITY_STATUS.ELIGIBLE;
    } catch {
      return false;
    }
  }

  /**
   * Updates the beneficiary details.
   *
   * @public
   * @async
   * @function updateBeneficiary
   * @param {Object} params - The parameters object.
   * @param {string} params.quoteId - The ID of the quote.
   * @param {string} params.beneficiaryId - The ID of the beneficiary to update.
   * @param {string} params.currency - The currency of the beneficiary to update.
   * @param {string} params.type - The payment type to associate the beneficiary with.
   * @param {Object} [params.details] - The new attributes to update the beneficiary with.
   * @returns {Promise<{ beneficiary: Beneficiary, fees: Fees, quote: Quote, targetAccountId: number }>} A promise that resolves to an object containing the patched quote, the updated beneficiary, the transfer fees and the target account ID.
   * @throws If the network request fails or if the response is invalid.
   */
  async updateBeneficiary({ quoteId, beneficiaryId, currency, type, details = {} }) {
    let url = this.#buildURL(`beneficiaries/${beneficiaryId}`);
    let response = await this.networkManager.request(url, {
      method: 'PUT',
      data: JSON.stringify({
        quoteId,
        payload: {
          currency,
          details,
          type,
        },
      }),
    });
    let { fees, quote, ...rest } = transformKeys(response);
    return { fees: normalizeFee(fees), quote: normalizeQuote(quote), ...rest };
  }

  /**
   * Builds a URL path with an optional query string.
   *
   * @private
   * @function buildPath
   * @param {string} endpoint - The base endpoint.
   * @param {Object} [params={}] - An object containing query parameters.
   * @returns {string} The URL path with the query string, if applicable.
   * @example
   * buildPath('/beneficiaries', { id: 123 }); // '/users?id=123'
   * buildPath('/transfers', { category: ['suppliers', 'salaries'] }); // '/transfers?category=suppliers,salaries'
   */
  #buildPath(endpoint, params = {}) {
    if (!Object.keys(params).length) {
      return endpoint;
    }

    let formattedParams = transformKeys(params, decamelize);
    let queryString = Object.entries(formattedParams)
      .map(([key, value]) => {
        if (Array.isArray(value) && value.length) {
          return `${key}=${value.map(encodeURIComponent).join(',')}`;
        }

        if (value !== undefined && value !== null) {
          return `${key}=${encodeURIComponent(value)}`;
        }

        return null;
      })
      .filter(Boolean)
      .join('&');

    return queryString ? `${endpoint}?${queryString}` : endpoint;
  }

  /**
   * Constructs a URL for outgoing international transfer requests by appending the provided path to the base URL.
   *
   * @private
   * @function buildURL
   * @param {string} path - The path to be appended to the base URL.
   * @param {string} endpoint - The endpoint to be appended to the base URL.
   * @param {Object} [params={}] - An object containing query parameters.
   * @returns {string} The constructed URL.
   */
  #buildURL(endpoint, params = {}) {
    return `${apiBaseURL}/${internationalOutNamespace}/international_out/${this.#buildPath(endpoint, params)}`;
  }
}
